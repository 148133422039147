import { cssBundleHref } from "@remix-run/css-bundle";
import styles from "~/app.css";
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration, useCatch, useLoaderData, useMatches, useRouteError } from
"@remix-run/react";
import fetchAPI from '~/utils/ssr.js';
import { Analytics, Footer, NavBar } from '~/legacy-components/index.js';
import favicon from './images/android-chrome-512x512.png';
import NotFound from '~/legacy-components/NotFound/NotFound.js';

export const links = () => [
{ rel: "stylesheet", href: "https://use.typekit.net/khv0txu.css" },
{ rel: "stylesheet", href: "https://use.typekit.net/yeg3kzz.css" },
{ rel: "stylesheet", href: cssBundleHref },
{ rel: "stylesheet", href: styles },
{ rel: "favicon", href: favicon },
{ rel: "manifest", href: '/manifest.json' }];


const gaTrackingId = 'G-59V67Y6MKZ';

export function ErrorBoundary() {
  const error = useRouteError();
  console.error('YBOS: An Error Occurred', error);

  const navlinks = [
  {
    "title": "YOUR BRAIN ON SPORT",
    "link": "/",
    "color": "lettuce"
  }];


  return (
    <html lang="en">
    <head>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width,initial-scale=1" />
      <Meta />
      <Links />
    </head>
    <body>
      <Analytics />
      {process.env.NODE_ENV === "development" ? null :
        <>
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`} />

          <script
            async
            id="gtag-init"
            dangerouslySetInnerHTML={{
              __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', '${gaTrackingId}', {
                  page_path: window.location.pathname,
                });
              `
            }} />

        </>}

      <header>
        <NavBar navLinks={navlinks} match={'/'} />
      </header>
      <div id="root">
        <NotFound status={error.status} />
        <ScrollRestoration />
        <Scripts />
        <LiveReload />
      </div>
      <footer>
        <Footer margin="none" />
      </footer>
    </body>
    </html>);

}

const getRouteMatch = (matches) => {
  if (matches.length === 0) return '/';

  const slug = matches.pop().params.slug ?? '';
  return `/${slug}`;
};

export default function App() {
  const { navLinks } = useLoaderData();
  const matches = useMatches();
  const match = getRouteMatch(matches);

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        <Analytics />
        {process.env.NODE_ENV === "development" ? null :
        <>
            <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`} />

            <script
            async
            id="gtag-init"
            dangerouslySetInnerHTML={{
              __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', '${gaTrackingId}', {
                  page_path: window.location.pathname,
                });
              `
            }} />

          </>}

        <header>
          <NavBar navLinks={navLinks} match={match} />
        </header>
        <div id="root">
          <Outlet />
          <ScrollRestoration />
          <Scripts />
          <LiveReload />
        </div>
        <footer>
          <Footer margin="none" />
        </footer>
      </body>
    </html>);

}

export const loader = async (context) => {
  const links = await fetchAPI('navlinks.json');
  if (links.status !== 200) {
    throw new Response(null, {
      status: links.status,
      statusText: links.props.error ?? 'Error fetching navlinks'
    });
  }

  return {
    navLinks: links.props
  };
};